import { ConfigDto } from '@portals/shared/admin/ConfigDto';

import { request } from './request';

export async function fetchConfigs(): Promise<ConfigDto[]> {
  const response = await request<ConfigDto[]>({
    url: 'runtime-config',
  });
  return response.data;
}
