





























import {
  TableColumn,
  TableComponent,
} from '@dnb-open-banking/vue-table-component';
import { ConfigDto } from '@portals/shared/admin/ConfigDto';
import Vue from 'vue';

import Loader from '@/components/Loader.vue';
import { fetchConfigs } from '@/service/configService';

type Data = {
  configs: ConfigDto[];
  loading: boolean;
  selectedConfig: Record<string, unknown> | null;
  create: boolean;
};

export default Vue.extend({
  name: 'runtime-config',
  components: {
    Loader,
    TableComponent,
    TableColumn,
  },
  data(): Data {
    return {
      configs: [],
      loading: false,
      selectedConfig: null,
      create: false,
    };
  },
  mounted() {
    this.loadConfigs();
  },
  methods: {
    async loadConfigs() {
      this.loading = true;
      this.configs = await fetchConfigs();
      this.loading = false;
    },
    async onConfigUpdate(key: string) {
      if (key === 'updated') {
        await this.loadConfigs();
      }
    },
  },
});
